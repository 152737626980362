@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Carbon Bold;
  src: url(../public/carbon.ttf) format("truetype"),url(../public/carbon.woff) format("woff"),url(../public/carbon.woff2) format("woff2")
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Space Mono', monospace;
}

.font-carbon {
  font-family: Carbon Bold,monospace;
}

.font-heading {
  font-family: 'Rubik', sans-serif;
}

.font-rubik {
  font-family: 'Rubik', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ct-chart {
  width: 100%;
}

.ct-label {
  color: #9ca3af;
  color: white;
}

.ct-vertical {
  stroke: #374151;
}

/* Comparaison series */
.ct-series-a .ct-line {
  stroke-width: 3px;
  stroke: #3B3B3F;
}

/* My returns */
.ct-series-b .ct-line {
  stroke: white;
}

.ct-legend {
  position: relative;
}

.ct-legend li {
  position: relative;
  padding-left: 22px;
  margin-bottom: 3px;
  display: inline-flex;
  align-items: center;
  margin-right: 28px;
  font-size: 0.8rem;
  font-family: 'Carbon Bold', monospace;

}

.ct-legend li:before {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    content: '';
    border: 3px solid transparent;
    border-radius: 2px;
}

.ct-legend li.inactive:before {
    background: transparent;
}

.ct-legend.ct-legend-inside {
    position: absolute;
    top: 0;
    right: 0;
}

.ct-legend .ct-series-1:before {
  background-color: white;
  border-color: white;
}

.ct-legend .ct-series-0:before {
  background-color: #3B3B3F;
  border-color: #3B3B3F;
}
